import { initAppInsights } from './log';

/**
 * Opt out of loading default font
 * @see https://github.com/PolymerElements/font-roboto/blob/master/roboto.js
 */
window.polymerSkipLoadingFontRoboto = true;

window.cz = window.cz || {};

const queryParams = new URL(window.location).searchParams,
	stringToBool = (string) => string === 'true';

Object.assign(window.cz, {
	config: window.cz && window.cz.config,
	debug: {
		// this is a getter to make it easier to toggle for testers instead of an assignment or method call
		get otsShowLocalHeaders() {
			document.getElementById('start').updateStyles({
				'--cosmoz-omnitable-local-filter-header-color': 'red',
			});
			return '🔦 Watch out for red Omnitable headers! 😱';
		},
	},
	options: {
		// custom auth providers can override this with query params
		idp: null,
		// backend base URI for any ajax requests
		backendBaseUri: undefined,
		frontendBaseUri: window.czcore.frontendBaseUri,
		googleClientId: undefined,
		debug: true,
	},
	ui: {},
	state: {
		app: undefined,
		currentLocationPath: queryParams.get('cpl'),
		currentNode: {},
		loggedIn: false,
		myItemsOnly: stringToBool(queryParams.get('mio')),
		offline: false,
		offlineLastRetry: new Date(),
		start: window.cz && window.cz.state && window.cz.state.start,
		tree: undefined,
		unsavedWork: false,
	},
	workers: {},
});

const fetchOpts = {
	credentials: 'include',
	mode: 'cors',
	// eslint-disable-next-line no-undef
	...(process.env.NODE_ENV === 'development' && { cache: 'force-cache' }),
};

fetch('cz.config.json').then(async (response) => {
	const config = await response.json();

	if (config.azureConnectionString) {
		initAppInsights(config.azureConnectionString);
	}

	Object.assign(window.cz, { config });

	const getApiCall = (call) => {
			return new URL(`${config.backendBaseUri}${call}`);
		},
		statusApiUrl = getApiCall('api/Auth/CheckStatus'),
		initClient = getApiCall('api/Boot/InitClient'),
		start = document.getElementById('start'),
		{ returnUrl, ...statusApiParams } = window.czcore.checkstatus.params;

	window.czcore.setUrlParams(statusApiUrl, statusApiParams);

	fetch(statusApiUrl, {
		...fetchOpts,
		body: JSON.stringify(returnUrl),
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	})
		.then(async (response) => {
			const authStatus = await response.json();

			// Redirect immediately if this is required by specified authentication provider
			if (authStatus.redirectUri) {
				window.location.href = authStatus.redirectUri;
				return;
			}

			if (response.ok) {
				start.language = authStatus.language;
				fetch(initClient, fetchOpts).then(async (response) => {
					const boot = await response.json();
					czcore.boot.set(boot);
				});
			}

			// cz-start hasn't been upgraded yet, but _authStatus will survive and become default value anyway
			start._authStatus = authStatus;
			import('./start.js');
		})
		.catch((error) => {
			start._authStatus = {
				authenticated: false,
				message: error.message,
			};
		});

	if (
		window.NREUM &&
		config.newRelicLicenseKey &&
		config.newRelicApplicationID
	) {
		const beacon = 'bam.nr-data.net';
		window.NREUM.info = {
			beacon,
			errorBeacon: beacon,
			licenseKey: window.cz.config.newRelicLicenseKey,
			applicationID: window.cz.config.newRelicApplicationID,
			sa: 1,
		};
	}

	window.dispatchEvent(new CustomEvent('cz-config-loaded'));
});

fetch('cz.version.json').then(async (response) => {
	const version = await response.json();
	Object.assign(window.cz, { version });
});

czcore.boot.increaseProgress(10, 'Core boot loaded');
czcore.boot.incLoop();
